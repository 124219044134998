<template>
  <div>
    <search-voucher @submit="handleSearch" />
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          :disabled="false"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="invalidAllUserVoucherEffective(record)" style="color: red">失效客户代金劵</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>

      <span slot="rule_content" slot-scope="rule_content, record">
        <a-space>
          <a @click="showRuleContentModal(record)">查看详情</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增优惠券模态框 -->
    <new-voucher
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="Voucher"
      :visible.sync="isShowOperationHistoryModal"
    />

    <a-modal
      v-if="isShowRuleContentModal"
      :visible.sync="isShowRuleContentModal"
      title="使用规则"
      @ok="isShowRuleContentModal=false"
      @cancel="isShowRuleContentModal=false"
    >
      <div>{{ editingRecord.rule_content }}</div>
    </a-modal>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchVoucher from '@/views/voucher/Search'
import { findVouchers, updateVoucherInvalid, updateVoucherEffective } from '@/api/voucher'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'Voucher',
  components: {
    Pagination,
    SearchVoucher,
    NewVoucher: () => import('@/views/voucher/New'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: {},
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      },
      data: [],
      loading: false,
      isShowNewModal: false,
      isShowOperationHistoryModal: false,
      isShowRuleContentModal: false,
      editingEffectiveId: null,
      editingRecord: null
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '活动名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 200
        },
        {
          title: '活动类型',
          dataIndex: 'activity_type',
          width: 100
        },
        {
          title: '活动截止期限',
          dataIndex: 'end_time',
          width: 120
        },
        {
          title: '活动状态',
          dataIndex: 'effective',
          key: 'effective',
          width: 100,
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '活动创建时间',
          dataIndex: 'created_at',
          width: 150,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '代金券金额(元)',
          dataIndex: 'amount',
          width: 150,
          customRender: formatCurrency
        },
        {
          title: '使用条件',
          dataIndex: 'use_type',
          width: 200
        },
        {
          title: '代金券有效期限',
          dataIndex: 'period_type',
          width: 150
        },
        {
          title: '使用规则',
          dataIndex: 'rule_content',
          key: 'rule_content',
          width: 100,
          scopedSlots: { customRender: 'rule_content' }
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findVouchers(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },
    handleSearch(query) {
      this.query = query
      this.fetchData()
    },
    sortChange(pagination, filters, sorter) {
      this.sort = {
        sort_field: sorter.field,
        sort_order: sorter.order
      }
      this.fetchData()
    },
    showNewModal() {
      this.isShowNewModal = true
    },
    invalidAllUserVoucherEffective(record) {
      this.$confirm({
        title: '确定失效客户代金券吗？失效后，客户已领取的此活动下的代金券都将自动失效，并从客户的代金券列表中删除，且无法进行恢复，请慎重操作。',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          updateVoucherInvalid(record.id).then((res) => {
            if (res.code === 0) {
              this.fetchData()
            }
          })
        }
      })
    },
    showOperationHistoryModal(id) {
      this.isShowOperationHistoryModal = true
      this.showingOperationHistoryId = id
    },
    showRuleContentModal(record) {
      this.isShowRuleContentModal = true
      this.editingRecord = record
    },
    changeEffective(checked, record) {
      const endTime = new Date(record.end_time)
      if (endTime < new Date() && record.effective === false) {
        this.$message.error('此活动截止日期已到，无法进行生效。')
        return
      }
      const effective = checked
      this.$confirm({
        title: effective ? '确定生效吗？生效后其他正在生效的同类型活动将字段失效，请慎重操作。' : '确定失效活动吗？失效后用户已领取的代金券不会失效，未过期的可以继续使用。',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.editingEffectiveId = record.id
          updateVoucherEffective(record.id, { effective: effective }).then((res) => {
            if (res.code === 0) {
              record.effective = checked
              this.editingEffectiveId = 0
              this.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
