import request from '@/utils/request'

export function findVouchers(params) {
  return request({
    url: `/vouchers`,
    method: 'get',
    params: params
  })
}

export function newVoucher(params) {
  return request({
    url: `/vouchers`,
    method: 'post',
    data: params
  })
}

export function updateVoucherEffective(id, params) {
  return request({
    url: `/vouchers/${id}/effective`,
    method: 'patch',
    data: params
  })
}

export function updateVoucherInvalid(id) {
  return request({
    url: `/vouchers/${id}/invalid`,
    method: 'patch'
  })
}

// 获取优惠券使用类型选项
export function findVoucherUseTypeOption() {
  return request({
    url: `/vouchers/option`,
    method: 'get'
  })
}
